import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { MeiliSearch } from 'meilisearch';
import InstantSearch from 'vue-instantsearch/vue3/es/src/components/InstantSearch';
import { h } from 'vue';

const host: string = import.meta.env.VITE_MEILISEARCH_HOST;
const indexName: string = import.meta.env.VITE_INDEX;
const apiKey: string | undefined = import.meta.env?.VITE_MEILISEARCH_KEY;

// https://github.com/meilisearch/meilisearch-js-plugins/tree/main/packages/instant-meilisearch#-documentation
const { searchClient } = instantMeiliSearch(host, apiKey, {
    primaryKey: 'id',
    finitePagination: true,
    keepZeroFacets: true,
    placeholderSearch: false, // do NOT show any results unless user typed/selected something
});

// https://github.com/meilisearch/meilisearch-js#-documentation
const client = new MeiliSearch({ host, apiKey });

const index = client.index(indexName);

export default function () {
    return {
        client,
        searchClient,
        index,
        indexName,

        InstantSearch: (props: any, { slots }: any) =>
            h(
                InstantSearch,
                {
                    indexName,
                    searchClient,
                    // https://www.algolia.com/doc/guides/building-search-ui/widgets/show-and-hide-widgets/vue/#enable-preservesharedstateonunmount
                    future: {
                        preserveSharedStateOnUnmount: true,
                    },
                    ...props,
                },
                slots
            ),
    };
}
